import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

const SliderItemHtml = ({contentType}) => {
    const [image, setImage] = useState('')
    const [altText, setAltText] = useState('')
    const [link, setAltLink] = useState('')
    
    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "image")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished.replace("https://storage.googleapis.com/cazeebodk.appspot.com/","https://ik.imagekit.io/cazeebo/")
                imageStr = imageStr + '?tr=w-' + window.innerWidth
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var linkVal = contentType.values.filter((value) => value.settingAlias === "link")[0]
            if(linkVal){
                if(linkVal.relations.length > 0){
                    let docUrl = (linkVal.relations[0].contentModel.isArea ? '/' + linkVal.relations[0].contentModel.modelUrl : '') + '/' + linkVal.relations[0].contentTypeUrl
                    setAltLink(docUrl)
                }else{
                    setAltLink(linkVal.contentPublished)
                }
            }
        }
    },[contentType])

    let content

    if(contentType){
        content = (
            <div key={contentType.id} className="carousel-item">
                {(link !== '') ?
                    (link.includes('http')) ?
                    <a href={link}>
                        <img src={image} alt={altText}/>
                    </a>
                    :
                    <Link to={link}>
                        <img src={image} alt={altText}/>
                    </Link>
                :
                <img src={image} alt={altText}/>
                }
                
            </div>
        )
    }

    return (
        <>
           {content} 
        </>
    )
}

export default SliderItemHtml
