import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

const ImageHtml = ({contentType, cssClass, innerHtml}) => {
    const [image, setImage] = useState('')
    const [altText, setAltText] = useState('')
    const [link, setAltLink] = useState('')

    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "image")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished.replace("https://storage.googleapis.com/cazeebodk.appspot.com/","https://ik.imagekit.io/cazeebo/")
                imageStr = imageStr + '?tr=w-' + window.innerWidth
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var linkVal = contentType.values.filter((value) => value.settingAlias === "link")[0]
            if(linkVal){
                if(linkVal.relations.length > 0){
                    let docUrl = (linkVal.relations[0].contentModel.isArea ? '/' + linkVal.relations[0].contentModel.modelUrl : '') + '/' + linkVal.relations[0].contentTypeUrl
                    setAltLink(docUrl)
                }else{
                    setAltLink(linkVal.contentPublished)
                }
            }
        }
    },[contentType])

    let content

    if(contentType){
        content = (
            <>
            {innerHtml ?
            
            <figure>                
                {(link !== '') ?
                    <Link key={contentType.id} className={cssClass} to={link}>
                        <picture>
                            <img src={image} alt={altText}/>
                        </picture>
                    </Link>
                :
                <img key={contentType.id} src={image} alt={altText}/>                
                }
            </figure>
            :
            <article key={contentType.id} className={`col-1`}>
                <div className="item image">
                    <figure>
                        {(link !== '') ?
                            <Link to={link}>
                                <picture>
                                    <img src={image} alt={altText}/>
                                </picture>
                            </Link>
                        :
                        <img src={image} alt={altText}/>
                        }
                    </figure>
                </div>
            </article>
            }
            </>
        )
    }

    return (
        <>
            {content}
        </>
    )
}

export default ImageHtml
