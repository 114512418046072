import {useState, useEffect} from 'react'
import SliderItemHtml from './SliderItemHtml'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SliderHtml = ({contentType}) => {
    const [slides, setSlides] = useState()
    const [contentTypeIdList, setContentTypeList] = useState()



    useEffect(()=>{
        if(contentType){
            setContentTypeList(contentType.values.filter((val) => val.settingAlias === "slides")[0].relations.map((rel)=> {
                return rel.id
            }))
        }
    },[contentType])


  const fetchContentTypes = async () => {
    var graphql = JSON.stringify({
        query: `query ($url:String, $contentTypeIds:[Guid]){
                    contentTypesById(contentTypeIds:$contentTypeIds) {
                        id
                        contentTypeId 
                        contentTypeName
                        published
                        publishDate
                        values(url:$url){
                            id
                            settingId
                            contentPublished
                            settingAlias
                            relations(url:$url){
                                id
                                contentTypeName
                                contentTypeUrl
                                contentModel{
                                    modelName
                                    modelUrl
                                    isArea
                                }
                            }
                        }
                        settings{
                            id
                            settingType
                            settingName
                            settingAlias
                            defaultValue
                        }
                        contentModel{
                            id
                            modelName
                        }
                    }
                }`,
        variables: {
            url:'url'
            ,contentTypeIds: contentTypeIdList
        }
      })
    const res = await fetch(global.config.GraphQL.url + global.config.Editor.publicToken ,{
        method:'POST',
        headers:{
            'Authorization':'Basic ' + global.config.Editor.authToken
            ,'Content-Type': 'application/json'
        },
        body: graphql
    })
    .then(response => response.text())
    .then(result => {return JSON.parse(result).data})
    .catch(error => console.log('error', error))
    const data = await res
    return data

}

useEffect(()=>{
    const getData = async () => {
        var data = await fetchContentTypes()
        if(data.contentTypesById){
            setSlides(data.contentTypesById)
        }
    }
    if(contentTypeIdList){
        getData()
    }
},[contentTypeIdList])

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    let content

    if(slides){
        content = (slides.map((slide)=> {
            return (<SliderItemHtml key={`slideritem-${slide.id}`} contentType={slide} cssClass={""}/>)
        }))
    }

    return (
        <>
        {(slides) &&
            <Carousel 
                responsive={responsive}
                ssr={true}
                transitionDuration={500}
                swipeable={true}
                draggable={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                >
            {content} 
            </Carousel>
        }
        </>
    )
}

export default SliderHtml
