import {useState, useEffect} from 'react'

const GetContentTypes = ({contentTypeIds, setContentTypes}) => {
    useEffect(()=>{
        const getData = async () => {
            var data = await fetchContentTypes()
            if(data.contentTypesById){
                setContentTypes(data.contentTypesById)
            }
        }
        if(contentTypeIds){
            getData()
        }
    },[contentTypeIds])


  const fetchContentTypes = async () => {
    var graphql = JSON.stringify({
        query: `query ($url:String, $contentTypeIds:[Guid]){
                    contentTypesById(contentTypeIds:$contentTypeIds) {
                        id
                        contentTypeId 
                        contentTypeName
                        contentTypeUrl
                        published
                        publishDate
                        values(url:$url){
                            id
                            settingId
                            contentPublished
                            settingAlias
                            relations(url:$url){
                                id
                                contentTypeName
                                contentTypeUrl
                                contentModel{
                                    modelName
                                    modelUrl
                                    isArea
                                }
                            }
                        }
                        settings{
                            id
                            settingType
                            settingName
                            settingAlias
                            settingDescription
                            showDescription
                            defaultValue
                            required
                        }
                        contentModel{
                            id
                            modelName
                        }
                    }
                }`,
        variables: {
            url:'url'
            ,contentTypeIds: contentTypeIds
        }
      })
    const res = await fetch(global.config.GraphQL.url + global.config.Editor.publicToken ,{
        method:'POST',
        headers:{
            'Authorization':'Basic ' + global.config.Editor.authToken
            ,'Content-Type': 'application/json'
        },
        body: graphql
    })
    .then(response => response.text())
    .then(result => {return JSON.parse(result).data})
    .catch(error => console.log('error', error))
    const data = await res
    return data

    }

    return (
        <>
            
        </>
    )
}

export default GetContentTypes
