import { useState } from 'react'
import SliderHtml from './SliderHtml'
import SliderItemHtml from './SliderItemHtml'
import ImageHtml from './ImageHtml'
import ImageWithTextHtml from './ImageWithTextHtml'
import HeaderHtml from './HeaderHtml'
import FooterHtml from './FooterHtml'
import FormHtml from './FormHtml'
import NavigationHtml from './NavigationHtml'
import NavigationItemHtml from './NavigationItemHtml'
import ArticleHtml from './ArticleHtml'
import PageHtml from './PageHtml'

const RenderHtml = ({contentType,innerHtml,cssClass,current,setCurrent}) => {
    const [contentTypeId] = useState(contentType.id)

    let containerClass = ''
    let content

    if(contentType){
        if(contentType.contentModel.modelName === "Slider"){
            containerClass = "carousel"
            content = (<SliderHtml contentType={contentType} cssClass={cssClass} innerHtml={innerHtml}/>)
        }else if(contentType.contentModel.modelName === "Slider item"){
            content = (<SliderItemHtml contentType={contentType} cssClass={cssClass}/>)
        }else if(contentType.contentModel.modelName === "Image"){
            content = (<ImageHtml contentType={contentType} cssClass={cssClass} innerHtml={innerHtml}/>)
        }else if(contentType.contentModel.modelName === "Image with text"){
            content = (<ImageWithTextHtml contentType={contentType} cssClass={cssClass} innerHtml={innerHtml}/>)
        }else if(contentType.contentModel.modelName === "Header"){
            content = (<HeaderHtml contentType={contentType} innerHtml={true} cssClass={cssClass} current={current}/>)
        }else if(contentType.contentModel.modelName === "Navigation"){
            content = (<NavigationHtml contentType={contentType} innerHtml={innerHtml} cssClass={cssClass} current={current}/>)
        }else if(contentType.contentModel.modelName === "Navigation Item"){
            content = (<NavigationItemHtml contentType={contentType} innerHtml={innerHtml} cssClass={cssClass} current={current}/>)
        }else if(contentType.contentModel.modelName === "Footer"){
            content = (<FooterHtml contentType={contentType} innerHtml={true} cssClass={cssClass} current={current}/>)
        }else if(contentType.contentModel.modelName === "Form"){
            content = (<FormHtml contentType={contentType} innerHtml={innerHtml} cssClass={cssClass}/>)
        }else if(contentType.contentModel.modelName === global.config.ArticleTypeName){
            content = (<ArticleHtml contentType={contentType} innerHtml={innerHtml} cssClass={cssClass}/>)
        }else if(contentType.contentModel.modelName === global.config.PageTypeName){
            content = (<PageHtml contentType={contentType} cssClass={cssClass}/>)
        }
    }

    return (
        <>
        {innerHtml === true ?
        <div key={contentTypeId} className={cssClass}>{content}</div>
        :
        <div className={`row content-type-${contentType.contentModel.id}`} data-name={ contentType.contentTypeUrl}>
            <div className={`container ${containerClass}`}>
                {content}
            </div>
        </div>
        }
        </>
    )
}

export default RenderHtml
