import {useState, useEffect} from 'react'

const ArticleHtml = ({contentType}) => {
    const [image, setImage] = useState('')
    const [altText, setAltText] = useState('')
    const [contentText, setContentText] = useState('')

    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "desktopImage")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished.replace("https://storage.googleapis.com/cazeebodk.appspot.com/","https://ik.imagekit.io/cazeebo/")
                imageStr = imageStr + '?tr=w-' + window.innerWidth
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var contentTextVal = contentType.values.filter((value) => value.settingAlias === "content")[0]
            setContentText(contentTextVal && contentTextVal.contentPublished)
        }
    },[contentType])

    let content

    if(contentType){
        console.log(contentType)
        content = (
            <>
            <article key={`article-heading-${contentType.id}`} className={`col-1 image-rte`}>
                <div className="item heading">
                    <h1>{contentType.contentTypeName}</h1>
                </div>
            </article>
            <article key={`article-content-${contentType.id}`} className={`col-1 image-rte`}>
                <div className="item image">
                    <div className="inner">
                        <img src={image} alt={altText}/>
                    </div>
                </div>
                <div className="item rte">
                    <div className="inner" dangerouslySetInnerHTML={{__html: contentText}} />
                </div>
            </article>
            </>
        )
    }

    return (
        <>
           {content} 
        </>
    )
}

export default ArticleHtml
