import {useState, useEffect} from 'react'

const ImageWithTextHtml = ({contentType}) => {
    const [image, setImage] = useState('')
    const [altText, setAltText] = useState('')
    const [contentText, setContentText] = useState('')

    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "image")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished.replace("https://storage.googleapis.com/cazeebodk.appspot.com/","https://ik.imagekit.io/cazeebo/")
                imageStr = imageStr + '?tr=w-' + window.innerWidth
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var contentTextVal = contentType.values.filter((value) => value.settingAlias === "content")[0]
            setContentText(contentTextVal && contentTextVal.contentPublished)
        }
    },[contentType])

    let content

    if(contentType){
        content = (
            <article key={contentType.id} className={`col-1 image-rte`}>
                <div className="item image">
                    <div className="inner">
                        <figure>
                            <picture>
                                <img src={image} alt={altText}/>
                            </picture>
                        </figure>
                    </div>
                </div>
                <div className="item rte">
                    <div className="inner" dangerouslySetInnerHTML={{__html: contentText}} />
                </div>
            </article>
        )
    }

    return (
        <>
            {content}
        </>
    )
}

export default ImageWithTextHtml
