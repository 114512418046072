import {useState, useEffect} from 'react'

const FormHtml = ({contentType}) => {
    const types = ["action", "actionType", "senderEmail","receiverEmail","emailSubject","successMessage", "errorPrefix", "submitBtnTxt"]
    const [settings, setSettings] = useState(contentType.settings.filter((s) => !types.includes(s.settingAlias)))
    const [requiredFields, setRequiredFields] = useState(contentType.settings.filter((s) => !types.includes(s.settingAlias) && s.required))
    const [values, setValues] = useState([])
    const [outputValues, setOutputValues] = useState([])
    const [errors, setErrors] = useState([])
    const [actionType, setActionType] = useState('api')
    const [action, setAction] = useState('')
    const [formAction, setFormAction] = useState()
    const [formPosted, setFormPosted] = useState(false)
    const [successMessage, setSuccessMessage] = useState()
    const [errorPrefix, setErrorPrefix] = useState()
    const [submitBtnTxt, setSubmitBtnTxt] = useState()


    useEffect(()=>{
        if(contentType){
            let a, at, m, ep, sbt
            try{
                m = contentType.values.filter((v) => v.settingAlias === "successMessage")[0].contentPublished
                setSuccessMessage(m)
            }catch(err){}
            try{
                ep = contentType.values.filter((v) => v.settingAlias === "errorPrefix")[0].contentPublished
                setErrorPrefix(ep)
            }catch(err){}
            try{
                sbt = contentType.values.filter((v) => v.settingAlias === "submitBtnTxt")[0].contentPublished
                setSubmitBtnTxt(sbt)
            }catch(err){}
            try{
                a = contentType.values.filter((v) => v.settingAlias === "action")[0].contentPublished
                setAction(a)
            }catch(err){}
            try{
                at = contentType.values.filter((v) => v.settingAlias === "actionType")[0].contentPublished
                if(at === "post"){
                    const fa = {action:a, method:"POST"}
                    setFormAction(fa)
                }
                setActionType(at)
            }catch(err){}
        }
    },[contentType])

    useEffect(()=>{
        if(contentType){
            setSettings(contentType.settings.filter((s) => !types.includes(s.settingAlias)))
        }
      
    },[errors])
    
    useEffect(()=>{
        if(values.length > 0){
            setOutputValues(values)
        }
      
    },[values])

    const validateForm = (e) => {
        let canSubmit = true
        console.log("validateForm")
        let error = []
        console.log(canSubmit)
        console.log(outputValues)
        requiredFields.map((rf) =>{
            if(values.filter((v) => v.key === rf.settingAlias && v.value !== "").length === 0){
                canSubmit = false;
                error.push(rf.settingAlias)
                console.log(error)
            }
        })
        setErrors(error)
        if(canSubmit === false){
            e.preventDefault();
        }
    }

    const postForm = async () => {
        let canSubmit = true
        console.log("postForm")
        let error = []
        requiredFields.map((rf) =>{
            if(outputValues.filter((v) => v.key === rf.settingAlias && v.value !== "").length === 0){
                canSubmit = false;
                error.push(rf.settingAlias)
                console.log(error)
            }
        })
        setErrors(error)
        if(canSubmit === true){
            var data = JSON.stringify(outputValues)
            const url = (action !== "" 
                            ? action 
                            : `${global.config.Api.url}${global.config.Editor.publicToken}/Email/${contentType.id}`)
            const res = await fetch(url,{
                method:'POST',
                headers:{
                    'Authorization':'Basic ' + global.config.Editor.authToken
                    ,'Content-Type': 'application/json'
                },
                body: data
            })
            setFormPosted(true)
        }
    }
    const handleChange = (e) => {
        var newList = values.filter((val)=> val.key !== e.target.name)//.concat({key:key,value:value})
        setValues([...newList, {key:e.target.name, value:e.target.value}])
        console.log([...newList, {key:e.target.name, value:e.target.value}])
        if(e.target.value !== ""){
            var errList = errors.filter((val)=> val !== e.target.name)//.concat({key:key,value:value})
            setErrors(errList)
        }
    }
    /*
    const handleChange = (key, value) => {
        var newList = values.filter((val)=> val.key !== key)//.concat({key:key,value:value})
        console.log(newList)
        setValues([...newList, {key:key, value:value}])
    }
    */

    let count = 0
    return (
        <article className={`col-1`}>
            <div className="item form">
            {(formPosted === false) && 
                <form {...formAction} >
                {(settings) &&
                    <>
                    {settings.map((setting) => {
                        const placeholder = (setting.showDescription ? {placeholder:setting.settingDescription} : {})
                        return (
                        <div key={setting.id} className={`form-element _${setting.settingType} ${errors.includes(setting.settingAlias) ? 'error' : ''}`}>
                            <span>{setting.settingName} {setting.required ? '*' : ''}</span>
                            {(setting.settingType === "string") && <input type="text" name={setting.settingAlias} onChange={handleChange} value={values[setting.settingAlias]} {...placeholder}/>}
                            {(setting.settingType === "select") && 
                                <select name={setting.settingAlias} onChange={handleChange}>
                                        {
                                            setting.defaultValue.split('|').filter((op)=> op !== "").map((option) => {
                                                const opt = option.split('¤')
                                                return (<option value={opt[0]}>{opt[1]}</option>)
                                            })
                                        }
                                </select>
                            }
                            {(setting.settingType === "radio") && 
                                <>
                                {(setting.defaultValue.split('|').filter((op)=> op !== "").map((option) => {
                                    const opt = option.split('¤')
                                    count++
                                    return (<label htmlFor={`lid_${setting.id}_${count}`}><input id={`lid_${setting.id}_${count}`} type="radio" name={setting.settingAlias} value={opt[0]}  onChange={handleChange}/> {opt[1]}</label>)
                                }))
                                }
                                </>
                            }
                            {(setting.settingType === "textarea") && <textarea name={setting.settingAlias} onChange={handleChange} {...placeholder}>{values[setting.settingAlias]}</textarea>}
                            {(setting.settingType === "bool") && <label htmlFor={`lid_${setting.id}`}><input id={`lid_${setting.id}`} type="checkbox" name={setting.settingAlias} onChange={handleChange}/></label>}
                            {(setting.required) && 
                                <span className="form-element-error">{errorPrefix} {setting.settingName}!</span>
                            }
                        </div>)
                        })}     
                </>   
                }
                {(actionType === "api") && <input type="button" name="submit" value={submitBtnTxt} onClick={postForm}/>}
                {(actionType === "post") && <input type="submit" name="submit" value={submitBtnTxt} onClick={validateForm}/>}
                
            </form>
            }
            {(actionType === "api" && formPosted === true) && 
                <div dangerouslySetInnerHTML={{__html: successMessage}}></div>
            }
        </div>
    </article>
    )
}

export default FormHtml
